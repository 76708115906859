import './Post.css';
import { PostProps, CodeMapping } from './Types';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import rawMapping from '../data/ArxivCodeToHumanName.json';
import { useNavigate } from 'react-router-dom';

const codeMapping: CodeMapping = rawMapping;

const Post: React.FC<PostProps> = (props) => {
  const { identifier, title, subjects, publication_date, authors, abstract } = props;
  const navigate = useNavigate();
  const formattedDate = new Date(publication_date * 1000).toLocaleDateString();

  const parseSubjects = (subjects: string[]) => {
    if (subjects.length === 0) {
      return <span className="post-subjects"></span>;
    }
    let result = [];
    for (let i = 0; i < subjects.length && result.length < 2; i++) {
      let subject = subjects[i].toLowerCase();
      if (codeMapping[subject]) {
        result.push(codeMapping[subject]);
      }
    }
    return (
      <div className="post-subjects">
        {result.length > 0 ? result.slice(0, 1).map((subject, index) => (
          <span key={index} className="post-subject-pill">{subject}</span>
        )) : subjects.slice(0, 1).map((subject, index) => (
          <span key={index} className="post-subject-pill">{subject}</span>
        ))}
      </div>
    );
  };

  return (
    <div className="post"
      onClick={() => navigate(`/${identifier}`, {
        state: { post: props }
      })}
      style={{ textDecoration: 'none', cursor: 'pointer' }}>
      <div className="post-link">
        <div className="post-header">
          {parseSubjects(subjects)}
          <span className="post-date-pill">{formattedDate}</span>
        </div>
        <h2 className="post-title"><Latex>{title}</Latex></h2>
        <div className="post-authors">{authors.join(', ')}</div>
        <div className="post-abstract"><Latex>{abstract}</Latex></div>
      </div>
    </div>
  );
};

export default Post;