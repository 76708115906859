// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUBUHHxyChItA_tVEvtOoQBvj3P6Ettpg",
  authDomain: "prime-depot-436400-u3.firebaseapp.com",
  projectId: "prime-depot-436400-u3",
  storageBucket: "prime-depot-436400-u3.appspot.com",
  messagingSenderId: "313410011479",
  appId: "1:313410011479:web:b3f2f36c20e0a1f2b8fe12",
  measurementId: "G-5P21XLSLCM"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export { db };