import { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs, QueryConstraint } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Post from './Post';
import { ArxivItem } from './Types';
import './Feed.css';

const Feed: React.FC = () => {
  const [posts, setPosts] = useState<ArxivItem[]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadingTriggerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (loading) {
      window.history.scrollRestoration = 'manual';
    } else {
      window.history.scrollRestoration = 'auto';
    }
  }, [loading]);

  const fetchPosts = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      const constraints = [
        orderBy('publication_date', 'desc'),
        limit(50),
        lastVisible ? startAfter(lastVisible) : undefined
      ].filter(Boolean) as QueryConstraint[];

      const q = query(collection(db, 'arxiv-items'), ...constraints);
      const documentSnapshots = await getDocs(q);

      if (documentSnapshots.empty) {
        setHasMore(false);
        return;
      }

      const newDocs = documentSnapshots.docs.map(doc => doc.data() as ArxivItem);
      const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      setPosts(prevPosts => {
        const existingIds = new Set(prevPosts.map(post => post.identifier));
        const uniqueNewDocs = newDocs.filter(doc => !existingIds.has(doc.identifier));
        return [...prevPosts, ...uniqueNewDocs];
      });

      setLastVisible(lastVisibleDoc);

      if (documentSnapshots.docs.length < 50) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, loading]);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        const triggerEntry = entries[0];
        if (triggerEntry?.isIntersecting && hasMore && !loading) {
          fetchPosts();
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0
      }
    );

    const currentTrigger = loadingTriggerRef.current;
    if (currentTrigger && hasMore) {
      observerRef.current.observe(currentTrigger);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [hasMore, loading, fetchPosts]);

  useEffect(() => {
    if (posts.length === 0) {
      fetchPosts();
    }
  }, [fetchPosts, posts.length]);

  return (
    <div className="feed">
      {posts.map((post, index) => (
        <Post
          key={`${post.identifier}-${index}`}
          identifier={post.identifier}
          title={post.title}
          subjects={post.subjects}
          publication_date={post.publication_date}
          authors={post.authors}
          abstract={post.abstract}
        />
      ))}
      {hasMore && (
        <div
          ref={loadingTriggerRef}
          style={{ height: '20px', margin: '10px 0' }}
        />
      )}
    </div>
  );
};

export default Feed;