import React from 'react';
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements, ScrollRestoration, Outlet } from 'react-router-dom';
import './App.css';
import Banner from './components/Banner';
import Feed from './components/Feed';
import FullPost from './components/FullPost';

// Root layout component
const RootLayout = () => {
  return (
    <div className="App">
      <ScrollRestoration getKey={(location, matches) => {
        return location.pathname;
      }} />
      <Banner />
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path="/" element={<Feed />} />
      <Route path="/:identifier" element={<FullPost />} />
    </Route>
  )
);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;