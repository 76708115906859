import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './FullPost.css';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import rawMapping from '../data/ArxivCodeToHumanName.json';
import { CodeMapping, PostProps } from './Types';
import { useLocation } from 'react-router-dom';

const codeMapping: CodeMapping = rawMapping;

const FullPost: React.FC = () => {
  const location = useLocation();
  const clicked_post = location.state?.post;

  const { identifier } = useParams<{ identifier: string }>();
  const [post, setPost] = useState<PostProps | null>(null);

  useEffect(() => {
    setPost(clicked_post);
  }, [clicked_post, identifier]);

  const parseSubjects = (subjects: string[]) => {
    if (!subjects || subjects.length === 0) {
      return <span className="post-subjects"></span>;
    }
    let result = [];
    for (let i = 0; i < subjects.length && result.length < 2; i++) {
      let subject = subjects[i].toLowerCase();
      if (codeMapping[subject]) {
        result.push(codeMapping[subject]);
      }
    }
    return (
      <div className="post-subjects">
        {result.length > 0 ? result.slice(0, 1).map((subject, index) => (
          <span key={index} className="post-subject-pill">{subject}</span>
        )) : subjects.slice(0, 1).map((subject, index) => (
          <span key={index} className="post-subject-pill">{subject}</span>
        ))}
      </div>
    );
  };

  if (!post) return <div className="full-post">Loading...</div>;

  return (
    <div className="full-post">
      <div className="post-header">
        {parseSubjects(post.subjects)}
        <span className="post-date-pill">
          {new Date(post.publication_date * 1000).toLocaleDateString()}
        </span>
      </div>
      <h2 className="post-title"><Latex>{post.title}</Latex></h2>
      <div className="post-authors">{post.authors.join(', ')}</div>
      <div className="post-abstract full"><Latex>{post.abstract}</Latex></div>
    </div>
  );
};

export default FullPost;