import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <header className="app-header">
      <h1>Melah</h1>
    </header>
  );
};

export default Banner;
